@import url(https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap);
body {
	margin: 0;
	/* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
	font-family: "Noto Sans", sans-serif !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: #f5f5f5;
}

::-webkit-scrollbar {
	width: 5px;
	background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
	background-color: #212529;
}

.float-right {
	float: right;
}

.text-right {
	text-align: right;
}

.close {
	float: right;
	font-size: 2rem;
	font-weight: 700;
	line-height: 1;
	color: #000;
	text-shadow: 0 1px 0 #fff;
	opacity: 0.5;
	background-color: transparent;
	border: 0;
}

.App .btn:focus,
.App .btn:active:focus {
	box-shadow: none;
}

/* App CSS */
input::-webkit-input-placeholder {
	/* Edge */
	color: gray;
	font-size: 13px;
}
input:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: gray;
	font-size: 13px;
}
input::placeholder {
	color: gray;
	font-size: 13px;
}
.App .form-control:focus {
	box-shadow: none;
	border-color: #007bff;
}
label {
	font-size: 13px;
}
/* App CSS */

/* Header CSS */
.header .navbar-brand i {
	margin-left: 10px;
}
.header .navbar-nav .avatar-icon {
	width: 30px;
	height: 30px;
	border-radius: 50%;
	background-color: #fff;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
}
.header .navbar-nav .avatar-icon i {
	color: #343a40;
}
.header .navbar-nav .nav-link {
	padding: 0.25rem;
}
/* Header CSS */

/* Wrapper CSS */
.wrapper {
	display: -webkit-flex;
	display: flex;
}
/* Wrapper CSS */

/* Sidebar CSS */
.sidebar {
	width: 225px;
	background-color: #212529;
	min-height: calc(100vh - 56px);
}
.sidebar .nav-item .nav-link {
	display: block;
	width: 100%;
	text-align: left;
	padding: 1rem;
	color: rgba(255, 255, 255, 0.5);
	font-size: 18px;
}
.sidebar .nav-item .nav-link.active {
	color: #fff;
}
.sidebar .nav-item .nav-link span {
	display: inline;
	margin-left: 10px;
}
/* Sidebar CSS */

/* Dashboard CSS */
.dashboard {
	width: calc(100% - 225px);
	padding-top: 1rem;
}
.dashboard .content .card-body {
	position: relative;
	overflow: hidden;
}
.dashboard .content .card-icon {
	position: absolute;
	z-index: 0;
	top: -1.25rem;
	right: -1rem;
	opacity: 0.4;
	font-size: 6rem;
	-webkit-transform: rotate(15deg);
	        transform: rotate(15deg);
}
.dashboard .content .card-body h2 {
	font-size: 40px;
}
/* Dashboard CSS */

/* Sidebar Toggled CSS*/
.App.toggled .sidebar {
	width: 90px;
	overflow: visible;
}
.App.toggled .sidebar .nav-item .nav-link {
	text-align: center;
	padding: 0.75rem 0.2rem;
	width: 90px;
}
.App.toggled .sidebar .nav-item .nav-link span {
	font-size: 0.75rem;
	display: block;
	margin-left: 0;
}
.App.toggled .dashboard,
.App.toggled .pos,
.App.toggled .users,
.App.toggled .categories {
	width: calc(100% - 90px);
}
.App.toggled .footer {
	width: calc(100% - 90px);
	left: 90px;
}
/* Sidebar Toggled CSS*/

/* Footer CSS */
.footer {
	position: absolute;
	bottom: 0;
	width: calc(100% - 225px);
	left: 225px;
}
.footer.fullWidth {
	width: 100%;
	left: 0;
}
.footer p {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	margin-bottom: 0;
	padding: 10px 0;
}
/* Footer CSS */

/* Login CSS */
.login {
	height: calc(100vh - 105px);
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
}
.login form {
	max-width: 400px;
	width: 100%;
	padding: 30px;
	box-shadow: 0px 2px 15px rgb(0 0 0 / 10%);
}
.login form h2 {
	position: relative;
	margin-bottom: 20px;
	text-align: center;
}
.login form h2::before,
.login form h2::after {
	content: " ";
	height: 1px;
	position: absolute;
	top: 20px;
	width: 30%;
	background-color: #212529;
}
.login form h2::before {
	left: 0;
}
.login form h2::after {
	right: 0;
}
.login form label {
	font-size: 13px;
	font-weight: bold;
}
.login form input:focus {
	box-shadow: none;
	border-color: #007bff;
}
.login .footer {
	width: 100%;
}
/* Login CSS */

/* Users CSS */
.users {
	width: calc(100% - 225px);
	padding-top: 1rem;
}
.users form .custom-file-input,
.users form .custom-file-label,
.users form .custom-file-label::after {
	height: 100%;
}
.users form .custom-file-label,
.users form .custom-file-label::after {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
}
.users form .custom-file-label::after {
	background-color: #007bff;
	color: #fff;
	border-color: #007bff;
}
.users .table .avatar {
	width: 50px;
	height: 50px;
	object-fit: cover;
}
.users .table tbody td:first-child {
	text-align: center;
}
.users .table tbody td {
	vertical-align: middle;
}
.switchToggle input[type="checkbox"] {
	height: 0;
	width: 0;
	visibility: hidden;
	position: absolute;
}
.switchToggle label {
	cursor: pointer;
	text-indent: -9999px;
	width: 70px;
	max-width: 70px;
	height: 30px;
	background: #d1d1d1;
	display: block;
	border-radius: 100px;
	position: relative;
}
.switchToggle label:after {
	content: "";
	position: absolute;
	top: 2px;
	left: 2px;
	width: 26px;
	height: 26px;
	background: #fff;
	border-radius: 90px;
	transition: 0.3s;
}
.switchToggle input:checked + label,
.switchToggle input:checked + input + label {
	background: #007bff;
}
.switchToggle input + label:before,
.switchToggle input + input + label:before {
	content: "Off";
	position: absolute;
	top: 5px;
	left: 35px;
	width: 26px;
	height: 26px;
	border-radius: 90px;
	transition: 0.3s;
	text-indent: 0;
	color: #fff;
}
.switchToggle input:checked + label:before,
.switchToggle input:checked + input + label:before {
	content: "On";
	position: absolute;
	top: 5px;
	left: 10px;
	width: 26px;
	height: 26px;
	border-radius: 90px;
	transition: 0.3s;
	text-indent: 0;
	color: #fff;
}
.switchToggle input:checked + label:after,
.switchToggle input:checked + input + label:after {
	left: calc(100% - 2px);
	-webkit-transform: translateX(-100%);
	        transform: translateX(-100%);
}
.switchToggle label:active:after {
	width: 60px;
}
/* Users CSS */

/* Categories CSS */
.categories {
	width: 100%;
	padding-top: 1rem;
}
/* Categories CSS */

/* Products CSS */
.products {
	width: 100%;
	padding-top: 1rem;
}
.products .table > :not(:first-child) {
	border-top-color: #deece6;
}
.products .table-head .input-group .form-control {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
	background-repeat: no-repeat;
	background-position: right 0.75rem center;
	background-size: 16px 12px;
}
.products .card-body {
	height: calc(100vh - 200px);
	overflow-y: auto;
}
/* Products CSS */

/* Error404 CSS */
.error404 {
	width: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
}
/* Error404 CSS */

